<template>
    <div class="form-field-layout" :class="css">
      <detect-input :field="field" :response="field.response" v-model="field.response.value" />
    </div>
</template>

<script>
import DetectInput from "@/components/forms/input/dynamic/DetectInput"
export default {
  name: "FieldLayout",
  props: {
    field: {
      type: Object,
      required: true
    }
  },
  computed: {
    layout () {
      return this.field && this.field.config && this.field.config.layout
    },
    width () {
      return this.field && this.field.config && this.field.config.width
    },
    css () {
      const css = []
      if (this.layout === 'newline') {
        //css.push('break-line')
      }
      if (this.width && this.width === '100%') {
        css.push('fullw')
      }
      return css
    }
  },
  components: {
    DetectInput
  }
}
</script>
