<template>
  <div class="app-form" :class="cssLayout">
    <div class="form-name" v-if="!hideName">{{form.name}}</div>
    <div class="fields-without-topics">
      <fields-group :fields="getFieldsFromTopic(null)" />
    </div>
    <div class="form-topics">
      <div v-for="topic in form.topics" :key="'topic-' + topic.id">
        <div class="topic-title">{{topic.name}}</div>
        <fields-group :fields="getFieldsFromTopic(topic.id)" />
      </div>
    </div>
    <div v-if="mode === 'edit'" class="form-footer">
      <u-btn v-if="!disableSaveBtn" label="Salvar" class="sl-btn m-r" color="positive" no-caps @click="save" :loading="saving" />
      <u-btn v-if="!disablePrinteBtn" class="sl-btn text-grey" color="white" no-caps @click="print" :loading="saving">
        <i class="fa-duotone fa-print m-r" /> <span>Imprimir</span>
      </u-btn>
    </div>
<!--    <div v-else class="form-footer text-right">
      <u-btn label="Atualizar informações" class="sl-btn" color="info" no-caps @click="edit"/>
    </div>-->
  </div>
</template>

<script>
import FieldsGroup from "@/components/forms/components/mount/FieldsGroup"
import {saveForm} from "@/domain/form/services"
export default {
  name: "MountForm",
  provide: function () {
    return {
      form: this
    }
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: 'edit'// edit|print
    },
    entityName: {
      type: String,
      required: true
    },
    entityId: {
      type: String,
      required: false
    },
    responseEntityId: {
      type: Number,
      required: false
    },
    hideName: {
      type: Boolean,
      default: false
    },
    disableSaveBtn: {
      type: Boolean,
      default: false
    },
    disablePrinteBtn: {
      type: Boolean,
      default: false
    },
    layout: {
      type: String,
      default: 'default'
    }
  },
  data () {
    return {
      saving: false,
      disableAlerts: false
    }
  },
  computed: {
    cssLayout () {
      const css = []
      css.push('layout-' + this.layout)
      return css
    }
  },
  methods: {
    getFieldsFromTopic (topic = null) {
      return this.form.fields.filter(f => {
        if (!topic) {
          return f.topic === null
        }
        return f.topic && f.topic.id === topic
      })
    },
    edit () {
      this.mode = 'edit'
    },
    async save () {
      this.saving = true
      await saveForm({
        form: this.form,
        entityName: this.entityName,
        entityId: this.entityId,
        responseEntityId: this.responseEntityId,
      })
          .then(response => {
            this.saving = false
            !this.disableAlerts && this.dg()
          })
          .catch(error => {
            this.saving = false
            this.alertApiError(error)
          })
    },
    print() {
      let edit = false
      if (this.mode === 'edit') {
        this.mode = 'show'
        edit = true
      }
      this.$nextTick(() => {
        const areaPrint = document.createElement('div')
        areaPrint.classList.add('removeAfterPrint')
        // header
        const extraHeader = document.querySelectorAll('.extra-print-header')
        if (extraHeader.length > 0) {
          extraHeader.forEach(function(node) {
            let printerCopy = node.cloneNode(true)
            printerCopy.classList.remove('hide')
            areaPrint.appendChild(printerCopy)
          });
        } else {
          console.log('Não há elementos extra correspondentes para impressão no header, imprimindo somente o necessário.')
        }

        //body
        const printer = document.querySelector('.app-form')
        const printerCopy = printer.cloneNode(true)
        printerCopy.classList.add('removeAfterPrint')

        // footer
        const extraFooter = document.querySelectorAll('.extra-print-footer')
        if (extraFooter.length > 0) {
          extraFooter.forEach(function(node) {
            let printerCopy = node.cloneNode(true)
            printerCopy.classList.remove('hide')
            areaPrint.appendChild(printerCopy)
          });
        } else {
          console.log('Não há elementos extra correspondentes para impressão no footer, imprimindo somente o necessário.')
        }
        areaPrint.appendChild(printerCopy)
        document.body.appendChild(areaPrint)
        const app = document.querySelector('#uloc-app')
        app.classList.add('hide')
        app.classList.add('print-view-active')
        printer.classList.add('hidden-print')
        this.$nextTick(() => {
          window.print()
          setTimeout(() => {
            app.classList.remove('hide')
            app.classList.remove('print-view-active')
            printer.classList.remove('hidden-print')
            let nodeToRemove = document.querySelector('.removeAfterPrint')
            document.body.removeChild(nodeToRemove)
          }, 300)
        })

        if (edit) {
          this.mode = 'edit'
        }
      })

    }
  },
  components: {
    FieldsGroup
  }
}
</script>
