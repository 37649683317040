<template>
    <div v-if="layout === 'newline'" class="form-field-layout break-line">
      <span class="section-title" v-if="pretitle">{{pretitle}}</span>
    </div>
</template>

<script>
export default {
  name: "FieldLayoutPre",
  props: {
    field: {
      type: Object,
      required: true
    }
  },
  computed: {
    layout () {
      return this.field && this.field.config && this.field.config.layout
    },
    pretitle () {
      return this.field && this.field.config && this.field.config.pretitle
    },
    css () {
      const css = []
      if (this.layout === 'newline') {
        css.push('break-line')
      }
      return css
    }
  },
  components: {
  }
}
</script>
