<template>
    <div v-if="layout === 'XXX'" class="form-field-layout break-line"></div>
</template>

<script>
export default {
  name: "FieldLayoutPos",
  props: {
    field: {
      type: Object,
      required: true
    }
  },
  computed: {
    layout () {
      return this.field && this.field.config && this.field.config.layout
    },
    css () {
      const css = []
      if (this.layout === 'newline') {
        css.push('break-line')
      }
      return css
    }
  },
  components: {
  }
}
</script>
