<template>
  <div class="form-fields-group clearfix">
    <template v-for="field in activeFields">
      <field-layout-pre :key="'pre-' + field.id" :field="field" />
      <field-layout :key="field.id" :field="field" />
      <field-layout-pos :key="'pos-' + field.id" :field="field" />
    </template>
  </div>
</template>

<script>
import FieldLayout from "@/components/forms/components/mount/FieldLayout"
import FieldLayoutPre from "components/forms/components/mount/FieldLayoutPre"
import FieldLayoutPos from "components/forms/components/mount/FieldLayoutPos"
export default {
  name: "FieldsGroup",
  props: {
    fields: {
      type: Array,
      required: true
    }
  },
  computed: {
    activeFields () {
      return this.fields.filter(f => f.active)
    }
  },
  components: {
    FieldLayoutPos,
    FieldLayoutPre,
    FieldLayout
  }
}
</script>

